<template>
  <modal name="PaymentPopup" class="payment-popup" transition="pop-out" :adaptive="true">
    <div class="payment-popup-container">
      <div class="payment-popup-inner-form">
        <h1 class="payment-popup-container__title">Payment</h1>
        <h3 class="payment-popup-container__subtitle">$500</h3>
        <div class="payment-popup-container__description">Access as a FLYCalendar member</div>

        <form
          class="form form--payment js-ajaxForm whitePicker"
          id="paymentForm"
          :class="{'loading' : loading}"
          @submit.prevent="submitHandler"
        >
          <Spinner/>
          <label class="form__label form__label--text">
            <div class="caption">Name:</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                type="text"
                v-model.trim="name"
                placeholder=""
              />
            </div>
            <span
              v-if="$v.name.$dirty && !$v.name.required"
              class="form__field-invalid form__field-invalid--right-side"
            >
                  This field is required
                </span>
          </label>
          <label
            class="form__label form__label--text">
            <div class="caption">Address:</div>
            <span class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    v-model.trim="address"
                  />
                </span>
<!--            <span-->
<!--              v-if="$v.address.$dirty && !$v.address.required"-->
<!--              class="form__field-invalid form__field-invalid&#45;&#45;right-side"-->
<!--            >-->
<!--                  This field is required-->
<!--                </span>-->
          </label>

          <!-- <div class="form__radioGroup">
            <label class="form__label form__label--radio">
              <input class="form__radio" type="radio" v-model="typeCard" value="credit"/>
              <i :class="{active: typeCard === 'credit'}" class="form__radio-icon"></i>
              <span class="form__radio-caption">Credit Card</span>
            </label>
            <label class="form__label form__label--radio">
              <input class="form__radio" type="radio" v-model="typeCard" value="debit"/>
              <i :class="{active: typeCard === 'debit'}" class="form__radio-icon"></i>
              <span class="form__radio-caption">ACH</span>
            </label>
          </div> -->

          <div class="card-details">
            <div class="card-info-top">
              <label class="form__label card form__label--text">
                <div class="form__caption">Card number:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field js-numberOnly"
                    type="text"
                    v-model="card_number"
                    v-mask="'####-####-####-####'"
                    minLength="19"
                    autoComplete="off"
                    required
                  />
                </div>
              </label>

              <label class="form__label form__label--date">
                <div class="form__caption">Expiration:</div>
                <div class="form__field-wrapper">
                  <a-date-picker
                    class="form__field js-gmi-datepicker"
                    placeholder=""
                    :showToday="false"
                    :allowClear="false"
                    v-model="date"
                    :format="'MM/DD/YYYY'"
                    :getCalendarContainer="getPickerContainer"
                  />
                </div>
              </label>
            </div>

            <div class="card-info-bottom">
              <label class="form__label cvv form__label--code">
                <div class="form__caption">CVV:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field js-numberOnly"
                    type="text"
                    v-model="code"
                    v-mask="'###'"
                    minLength="3"
                    autoComplete="off"
                    required
                  />
                </div>
              </label>

              <label class="form__label form__label--code">
                <div class="form__caption">Billing Address:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    v-model="billing_address"
                    required
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>
          <button class="buttonGreen"><span class="text">Send Payment</span></button>
        </form>
      </div>

      <div class="modal__close transaction-popup__close" @click="closePopup">
        <SvgIcon name="close-white"/>
      </div>
    </div>
  </modal>

</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import Spinner from '@/components/common/Spinner/Spinner'
import './PaymentPopup.styl'
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'PaymentPopup',
  data: () => ({
    name: '',
    typeCard: 'credit',
    address: '',
    loading: false,
    card_number: false,
    expiration: '',
    code: '',
    date: '',
    billing_address: '',
  }),
  components: {
    SvgIcon,
    Spinner,
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    opened () {
      if (document.querySelector('html').classList.contains('d-mobile')) {
        const container = document.querySelector('.vm--container')
        const popup = document.querySelector('.bookRoutesPopup')

        container.classList.add('js-bookRoutesPopup')
        document.querySelector('body').appendChild(container)
        setTimeout(() => {
          popup.style.opacity = 1
        }, 300)
      }
    },
    getPickerContainer (trigger) {
      console.log({trigger});
      return document.querySelector('.whitePicker')
    },
    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return false
      }
      const expirationDate = new Date(this.date);
      const expMonth = expirationDate.getMonth();
      const expYear = expirationDate.getFullYear();
      const reqData = {
        name: this.name,
        address: this.billing_address,
        cardNumber: this.card_number,
        expMonth,
        expYear,
        cvc: this.code,
      }
      const response = this.$store.dispatch('submitFlyCalPayment', reqData);
      if (response) {
        const message = {
          title: 'FlyCalendar Payment Submitted',
          text: 'You have purchased one year of FlyCalendar membership. Please resubmit FlyCal request.',
        }
        this.$modal.hide('PaymentPopup');
        this.$modal.show('ThanksPopup', {message})
      } else {
        const message = {
          title: 'Payment Failed',
          text: 'Your FlyCalendar membership payment has failed, please try again or contact us.',
        }
        this.$modal.hide('PaymentPopup');
        this.$modal.show('ThanksPopup', {message})
      }
    },
    async closePopup () {
      this.$modal.hide('PaymentPopup');
    },
  },
}
</script>
